import React from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ data, options }) => {
    let doughnutChart;
    if (data) {
        doughnutChart = (
            <React.Fragment>
                <div>
                    <Doughnut data={data} options={options} />
                </div>
            </React.Fragment>
        );
    }

    return <div>{doughnutChart}</div>;
};

export default DoughnutChart;
