import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const BarChart = ({ data, options }) => {
    let barChart;
    if (data) {
        barChart = (
            <React.Fragment>
                <div>
                    <Bar
                        data={data}
                        options={options}
                    />
                </div>
            </React.Fragment>
        );
    }

    return <div>{barChart}</div>;
};

export default BarChart;
