import React, { Component } from "react";
import BarChart from "./components/barChart";
import DoughnutChart from "./components/doughnutChart";
class Unwrapped extends Component {
    constructor(props) {
        super(props);
        this.getUnwrappedData();
        this.state = {
            unwrappedLoaded: false,
            unwrappedObject: {}
        }
    }

    generateGivenPieData() {
        let tempObj = [
            {
                type: "Regular",
                givenCount: this.extractData("plainGiven", "plain_given", false),
            },
            {
                type: "Certification",
                givenCount: this.extractData(
                    "certificationGiven",
                    "certification_given",
                    false
                ),
            },
            {
                type: "Birthday",
                givenCount: this.extractData(
                    "birthdayGiven",
                    "birthday_given",
                    false
                ),
            },
        ];
        return tempObj;
    }

    unwrappedHasLoaded() {
        const givenPieData = this.generateGivenPieData();
        this.setState({
            hourBarData: {
                labels: this.state.unwrappedObject["byHour"].map(
                    (data) => data.hour_formatted
                ),
                datasets: [
                    {
                        label: "Burritos",
                        backgroundColor: "rgba(246, 167, 85, 0.2)",
                        borderColor: "rgb(246, 167, 85)",
                        borderWidth: 1,
                        data: this.state.unwrappedObject["byHour"].map(
                            (data) => data.sum_given_by_hour
                        ),
                    },
                ],
            },
            dayBarData: {
                labels: this.state.unwrappedObject["byDoW"].map((data) =>
                    this.convertToDayName(data.day_formatted)
                ),
                datasets: [
                    {
                        label: "Burritos",
                        backgroundColor: "rgba(246, 167, 85, 0.2)",
                        borderColor: "rgb(246, 167, 85)",
                        borderWidth: 1,
                        data: this.state.unwrappedObject["byDoW"].map(
                            (data) => data.sum_given_by_day
                        ),
                    },
                ],
            },
            monthBarData: {
                labels: this.state.unwrappedObject["byMonth"].map(
                    (data) => data.month_formatted
                ),
                datasets: [
                    {
                        label: "Burritos",
                        backgroundColor: "rgba(246, 167, 85, 0.2)",
                        borderColor: "rgb(246, 167, 85)",
                        borderWidth: 1,
                        data: this.state.unwrappedObject["byMonth"].map(
                            (data) => data.sum_given_by_month
                        ),
                    },
                ],
            },
            givenDoughData: {
                labels: givenPieData.map((data) => data.type),
                datasets: [
                    {
                        label: "Burritos Given",
                        backgroundColor: [
                            "rgba(0, 101, 153, 0.2)",
                            "rgba(245, 155, 0, 0.2)",
                            "rgba(11, 145, 108, 0.2)",
                        ],
                        borderColor: [
                            "rgb(0, 101, 153)",
                            "rgb(245, 155, 0)",
                            "rgb(11, 145, 108)",
                        ],
                        borderWidth: 1,
                        data: givenPieData.map((data) => data.givenCount),
                    },
                ],
            },
            barOptions: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
            doughnutOptions: {
                plugins: {
                    legend: {
                        display: true,
                    },
                    title: {
                        display: true,
                        text: "Burritos Given by Type",
                    },
                },
            },
            unwrappedLoaded: true
        });
    }

    formatUsernameString(username) {
        if (!username) {
            return "some other bot";
        }
        if (username.lastIndexOf("@") > 0) {
            return username.substring(0, username.lastIndexOf("@"));
        } else {
            return username;
        }
    }

    convertToDayName(dayOfWeek) {
        const weekdays = new Array(7);
        weekdays[1] = "Sunday";
        weekdays[2] = "Monday";
        weekdays[3] = "Tuesday";
        weekdays[4] = "Wednesday";
        weekdays[5] = "Thursday";
        weekdays[6] = "Friday";
        weekdays[7] = "Saturday";
        return weekdays[dayOfWeek];
    }

    extractData(queryName, key, isArray) {
        let tempObj = this.state.unwrappedObject[queryName];
        return isArray ? tempObj : tempObj[0] ? tempObj[0][key] : 0;
    }

    // checkForData(data, attribute, returnVal) {
    //     if(data && data[attribute] !== undefined) {
    //         return data[attribute];
    //     } else {
    //         return returnVal;
    //     }
    // };

    getUnwrappedData() {
        let calloutURI = "/api/v1/unwrapped/team";
        // let calloutURI = "https://www.yoburrito.app/api/v1/user/U94Q4683S";
        fetch(calloutURI, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then((responseJSON) => {
                this.setState({
                    unwrappedObject: JSON.parse(responseJSON.json)
                });
                this.unwrappedHasLoaded();

            })
            .catch((error) => {
                console.log("callout error ===> " + error);
            });
    };

    render() {
        let unwrapped;
        if(this.state.unwrappedLoaded) {
            unwrapped = (
                <React.Fragment>
                    <div class="slds-size_1-of-1 slds-card slds-box">
                        <p class="slds-text-heading_large slds-text-align_center">
                            <b>
                                CS 2022{" "}
                                <span role="img" aria-label="Burrito">
                                    🌯
                                </span>{" "}
                                Unwrapped
                            </b>
                        </p>
                        <div class="slds-size_1-of-1 slds-var-p-vertical_x-small">
                            <p class="slds-text-heading_large slds-text-align_center">
                                In 2022,{" "}
                                <b>
                                    {this.extractData(
                                        "distinctGivers",
                                        "all_distinct_givers",
                                        false
                                    )}
                                </b>{" "}
                                CS team members sent an incredible combined{" "}
                                <b>
                                    {this.extractData(
                                        "allGiven",
                                        "all_given",
                                        false
                                    )}
                                </b>{" "}
                                burritos to{" "}
                                <b>
                                    {this.extractData(
                                        "distinctReceivers",
                                        "all_distinct_receivers",
                                        false
                                    )}
                                </b>{" "}
                                friends! Let's take a deeper look at where all of
                                that recognition went.
                            </p>
                            <p class="slds-text-heading_medium slds-var-p-top_small slds-align_absolute-center">
                                Here are the goodies all broken out:
                            </p>
                            <div class="slds-grid slds-wrap">
                                <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-2">
                                    <ul class="slds-var-p-horizontal_small slds-list_dotted slds-var-p-top_x-small">
                                        <li>
                                            <b>
                                                {this.extractData(
                                                    "plainGiven",
                                                    "plain_given",
                                                    false
                                                )}
                                            </b>{" "}
                                            regular 'ol burritos sent for any reason
                                            imaginable
                                        </li>
                                        <li>
                                            <b>
                                                {this.extractData(
                                                    "birthdayGiven",
                                                    "birthday_given",
                                                    false
                                                )}
                                            </b>{" "}
                                            Birthday Burritos were sent to celebrate{" "}
                                            <b>
                                                {this.extractData(
                                                    "birthdayThreads",
                                                    "birthday_thread_count",
                                                    false
                                                )}
                                            </b>{" "}
                                            teammate birthdays
                                        </li>
                                        <li>
                                            <b>
                                                {this.extractData(
                                                    "certificationGiven",
                                                    "certification_given",
                                                    false
                                                )}
                                            </b>{" "}
                                            Certification Burritos were delivered to
                                            reward{" "}
                                            <b>
                                                {this.extractData(
                                                    "certificationThreads",
                                                    "certification_thread_count",
                                                    false
                                                )}
                                            </b>{" "}
                                            total certifications earned
                                        </li>
                                    </ul>
                                </div>
                                <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-2 slds-align_absolute-center">
                                    {this.state.givenDoughData && (
                                        <DoughnutChart
                                            data={this.state.givenDoughData}
                                            options={this.state.doughnutOptions}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-size_1-of-1 slds-card slds-box">
                        <p class="slds-text-heading_medium slds-text-align_center">
                            With all of those 'ritos slung back and forth, these
                            folks topped the charts. Way to go!
                        </p>
                        <div class="slds-grid slds-wrap">
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium slds-var-p-bottom_small slds-text-align_center">
                                    <b>Top Givers</b>
                                </p>
                                <table class="tableClass">
                                    <thead>
                                        <tr class="">
                                            <th
                                                class="slds-truncate"
                                                title="Teammate"
                                                id="given_by_username"
                                            >
                                                Teammate
                                            </th>
                                            <th
                                                class="slds-truncate"
                                                title="Total Given"
                                                id="sum_burritos"
                                            >
                                                Total Given
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.extractData(
                                            "topGivers",
                                            null,
                                            true
                                        ).map((line, id) => {
                                            return (
                                                <tr class="" key={"row" + id}>
                                                    <td
                                                        class="slds-truncate"
                                                        title="Teammate"
                                                        data-label="Teammate"
                                                        id={
                                                            "given_by_username-" +
                                                            id
                                                        }
                                                        key={
                                                            "given_by_username-" +
                                                            id
                                                        }
                                                    >
                                                        <span class="dataSpace">
                                                            {this.formatUsernameString(
                                                                line.given_by_username
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="slds-truncate"
                                                        title="Total Given"
                                                        data-label="Total Given"
                                                        id={"sum_burritos-" + id}
                                                        key={"sum_burritos-" + id}
                                                    >
                                                        <span class="dataSpace">
                                                            {line.sum_burritos}
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium slds-var-p-bottom_small slds-text-align_center">
                                    <b>Top Receivers</b>
                                </p>
                                <table class="tableClass">
                                    <thead>
                                        <tr class="">
                                            <th
                                                class="slds-truncate"
                                                title="Teammate"
                                                id="given_by_username"
                                            >
                                                Teammate
                                            </th>
                                            <th
                                                class="slds-truncate"
                                                title="Total Received"
                                                id="sum_burritos"
                                            >
                                                Total Received
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.extractData(
                                            "topReceivers",
                                            null,
                                            true
                                        ).map((line, id) => {
                                            return (
                                                <tr class="" key={"row" + id}>
                                                    <td
                                                        class="slds-truncate"
                                                        title="Teammate"
                                                        data-label="Teammate"
                                                        id={
                                                            "given_to_username-" +
                                                            id
                                                        }
                                                        key={
                                                            "given_to_username-" +
                                                            id
                                                        }
                                                    >
                                                        <span class="dataSpace">
                                                            {this.formatUsernameString(
                                                                line.given_to_username
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="slds-truncate"
                                                        title="Total Received"
                                                        data-label="Total Received"
                                                        id={"sum_burritos-" + id}
                                                        key={"sum_burritos-" + id}
                                                    >
                                                        <span class="dataSpace">
                                                            {line.sum_burritos}
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="slds-size_1-of-1 slds-card slds-box">
                        <p class="slds-text-heading_medium slds-text-align_center">
                            Feeling lucky? There were{" "}
                            <b>
                                {this.extractData(
                                    "lotteryPlays",
                                    "lottery_plays",
                                    false
                                )}
                            </b>{" "}
                            lottery plays in 2022
                        </p>
                        <div class="slds-grid slds-wrap">
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">
                                    <b>
                                        {this.extractData(
                                            "neverLottery",
                                            "never_winners",
                                            false
                                        )}
                                    </b>{" "}
                                    active people have <b>NEVER</b> won the lottery
                                </p>
                                <p class="slds-text-heading_title">
                                    OG Andy won once in 2022, and has won twice
                                    total.
                                </p>
                            </div>
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">
                                    <b>
                                        {
                                            this.extractData(
                                                "multipleLotteryWinners",
                                                null,
                                                true
                                            ).length
                                        }
                                    </b>{" "}
                                    burrito-ers won the lottery multiple times this year!
                                </p>
                                <p class="slds-text-heading_title">
                                    Where did they get that kind of luck?
                                </p>
                                <table class="tableClass">
                                    <thead>
                                        <tr class="">
                                            <th
                                                class="slds-truncate"
                                                title="Teammate"
                                                id="given_by_username"
                                            >
                                                Teammate
                                            </th>
                                            <th
                                                class="slds-truncate"
                                                title="Wins"
                                                id="burritosCount"
                                            >
                                                Wins
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.extractData(
                                            "multipleLotteryWinners",
                                            null,
                                            true
                                        ).map((line, id) => {
                                            return (
                                                <tr class="" key={"row" + id}>
                                                    <td
                                                        class="slds-truncate"
                                                        title="Teammate"
                                                        data-label="Teammate"
                                                        id={
                                                            "given_to_username-" +
                                                            id
                                                        }
                                                        key={
                                                            "given_to_username-" +
                                                            id
                                                        }
                                                    >
                                                        <span class="dataSpace">
                                                            {this.formatUsernameString(
                                                                line.given_to_username
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="slds-truncate"
                                                        title="Wins"
                                                        data-label="Wins"
                                                        id={"burritosCount-" + id}
                                                        key={"burritosCount-" + id}
                                                    >
                                                        <span class="dataSpace">
                                                            {line.burritosCount}
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="slds-size_1-of-1 slds-card slds-box">
                        <p class="slds-text-heading_medium slds-text-align_center">
                            Giving Stats{" "}
                            <span class="slds-text-body_small">
                                *All times Central
                            </span>
                        </p>
                        <div class="slds-grid slds-wrap">
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">
                                    <b>By Month</b>
                                </p>
                                {this.state.monthBarData && (
                                    <BarChart
                                        data={this.state.monthBarData}
                                        options={this.state.barOptions}
                                    />
                                )}
                            </div>
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">
                                    <b>By Day of Week</b>
                                </p>
                                {this.state.dayBarData && (
                                    <BarChart
                                        data={this.state.dayBarData}
                                        options={this.state.barOptions}
                                    />
                                )}
                            </div>
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-3 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">
                                    <b> By Hour of Day</b>
                                </p>
                                {this.state.hourBarData && (
                                    <BarChart
                                        data={this.state.hourBarData}
                                        options={this.state.barOptions}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return <div class="slds-grid slds-wrap">{unwrapped}</div>;
    }
}

export default Unwrapped;
